.saveBtn{
  background-color: #ea4e29;
  border: none;
  &:hover, &:focus{
    background-color: #ea4e29;
    opacity: 0.7;
  }
}

.configPage{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .body{
    .configDateSelect{
      width: 200px;
    }
  }
}

.headerMain{
  display: flex;
  height: 100px;
  .title{
    flex: 1;
    .text{
      font-size: 35px;
      font-family: 'Josefin Sans', sans-serif;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #ea4e29;
      margin-bottom: -14px;

    }
  }
}

.btnContainer{
  padding-top: 30px;
}

.bounceText{
  display: flex;
  align-items: center;
  justify-content: center;
}

.input{
  margin-bottom: 0;
}