.mySwitch{
  display: inline-flex;
  justify-content: center;
  align-items: center;
    :global{
          .ant-switch-checked {
                background-color: #ea4e29!important;
          }
    }
  span{
    margin-left: 8px;
    font-size: 16px;
  }
}