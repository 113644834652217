.changDomainContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dfdfe0;
  border-radius: 4px;
  height: 46px;
  margin-left: 16px;
}
.userBtn{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  padding: 0 20px;
  color: #a5a5a9;
  svg{
    margin-left: 4px;
    margin-top: 0;
    color: #a5a5a9;
  }
  &:hover{
    opacity: 0.7;
  }
}

.userName{
  font-weight: bold;
  padding: 0 20px;
}

.divider{
  margin: 0px;
}

.dropdownTrigger{
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
  &:hover{
    opacity: 0.7;
  }
  svg{
    margin: 0px 4px;
    font-size: 14px;
  }
  &:hover{
    .arrow{
      color: red;
    }
  }
}

.headerLeftSide {
  height: 100%;
  display: flex;
  align-items: center;

  .header-button {
    margin-left: 16px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 53px;
    height: 53px;
    border-radius: 5px;
    border: none;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.97;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    box-shadow: none;
    .anticon {
      display: flex;
    }
  }
  .header-button-icon {
    width: 53px;
  }
}