.analytics-table {
    font-family: 'Nunito', sans-serif;
    .table {
        span {
            font-size: 15px;
            font-weight: 600;
            font-family: 'Nunito', sans-serif;
            font-stretch: normal;
            font-style: normal;
            line-height: 3.13;
            letter-spacing: normal;
        }
    }
    .subject {
        width: 200px;
        cursor: pointer;
    }
    .status{
        text-transform: uppercase;
    }
    .ant-table-thead {
        .ant-table-cell {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.75;
            letter-spacing: normal;
            text-align: left;
            color: #383841;
        }
        .ant-table-selection {
            display: none !important;
        }
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
        background: #edf8fb;
    }
    .ant-table-tbody > tr > td {
        // height: 5px;
        // padding: 4px;
    }
    .ivite-number {
        font-family: 'Nunito', sans-serif;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.13;
        letter-spacing: normal;
        text-align: left;
        color: #ea4e29;
    }
    .ant-table-thead > tr > th {
        background-color: #fff;
    }
    .ant-table-cell:not(:last-child) {
        border-right: none !important;
    }
    .ant-table-cell:last-child {
        border-right: 1px solid #f0f0f0;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border: 1px solid rgba(56, 56, 65, 0.53) !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-indeterminate .ant-checkbox-inner {
        background-color: #383841;
        border: 1px solid rgba(56, 56, 65, 0.53) !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-indeterminate .ant-checkbox-inner:hover {
        background-color: #383841;
        border: 1px solid rgba(56, 56, 65, 0.53) !important;
    }
    // .ant-checkbox {
    //     .ant-checkbox-inner {
    //         border: 1px solid #1bba79;
    //         background-color: transparent;
    //     }
    // }
    .ant-table-pagination {
        position: absolute;
        right: -15px;
        top: -70px;
    }
    .report-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}
