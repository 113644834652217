.card{
  background-color: #FFFFFF;
  min-height: 360px;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  height: 360px;
}
.title{
  color: #383841;
  font-size: 24px;
  font-weight: bold;
}
