.menu-arrow-left {
  position: absolute;
  bottom: 120px;
  right: 0;
  z-index: 1;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #56565d;
  cursor: pointer;

  &_opened {
    background-color: #383841;

  }
}
.menu-progress-title {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  //line-height: 2.24;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.menu-title {
  // width: 121px;
  height: 19px;
  margin: 25px 85px 44px 22px;
  font-family: Nunito;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.24;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.new-left-menu-wrapper {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  &_footer {
    border-top: 1px solid silver;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #cfcfcf;
  }
  .submenu-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 2;
    background-color: #1d1d21;
    .submenu {
      min-height: 50%;
      background-color: #1d1d21;
      .ant-menu-item-selected:after {
        display: none;
      }
      .ant-menu-item-selected {
        display: flex;
        align-items: center;
        color: #ea4e29 !important;
      }
      .ant-menu-item {
        display: flex;
        align-items: center;
        color: #cfcfcf;
        height: 47px;
        padding-left: 16px!important;
        svg {
          width: 19px;
          height: 19px;
        }
        .menu-item-title {
          font-family: Nunito;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.94;
          letter-spacing: normal;
          text-align: left;
        }
      }
    }
  }

  .new-left-menu {
    flex: 1;
    background-color: inherit;
    padding-bottom: 80px;

    &_closed {
      max-width: 70px;
      .ant-menu-item {
        margin: 0;
      }
    }
    .ant-menu-item {
      display: flex;
      height: 47px;
      align-items: center;
      color: #eaeaea;
      svg {
        width: 19px;
        height: 19px;
      }
      .menu-item-title {
        font-family: Nunito;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.94;
        letter-spacing: normal;
        text-align: left;
      }
    }
    .ant-menu-item-selected {
      background-color: #ea4e29 !important;
      height: 47px;
      display: flex;
      align-items: center;
      //color: #ea4e29;
      // margin-left: 15px;
      //border-bottom-left-radius: 7px;
      //border-top-left-radius: 7px;
    }
    .ant-menu-item-selected:after {
      display: none;
    }
  }
}

.progressAnalytics{
  margin-left: 0px!important;
}
