.card{
  background-color: #FFFFFF;
  min-height: 360px;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  display: flex;
  //justify-content: center;
  padding: 40px;
  flex-direction: column;
}
.title{
  color: #383841;
  font-size: 24px;
  font-weight: bold;
}

.desc{
  margin-top: 8px;
  color: #383841;
  font-size: 14px;
}

.description{
    color: #383841;
    font-size: 16px;
    font-weight: normal;

}
.dataSection{
  border-radius: 8px;
  &_dataContainer1{
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 0 16px;
    height: 44px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #FFFFFF;
    span{
      display: flex;
      align-items: center;
      svg{
        margin-right: 8px;
      }
    }
  }
  &_dataContainer2{
    padding: 0 16px;
    height: 44px;
    background-color: #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #FFFFFF;
    span{
      display: flex;
      align-items: center;
      svg{
        margin-right: 8px;
      }
    }
  }
  &_dataContainer3{
    //border-bottom-right-radius: 8px;
    //border-bottom-left-radius: 8px;
    padding: 0 16px;
    height: 44px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #FFFFFF;
    span{
      display: flex;
      align-items: center;
      svg{
        margin-right: 8px;
      }
    }
  }
  &_dataContainer4{
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 0 16px;
    height: 44px;
    background-color: #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
      display: flex;
      align-items: center;
      svg{
        margin-right: 8px;
      }
    }
  }
  &_title{
    color: #7c7c7c;
  }
}