.breadcrumbs{
    .breadcrumbs-items, a{
        font-family: 'Nunito', sans-serif;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.97;
        letter-spacing: normal;
        text-align: left;
        color: #383841 !important;
        
    }
    .breadcrumbs-items-active > a{
        color: #ea4e29 !important;
        
    }
}