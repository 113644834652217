.set-domain-email-modal {
    border-radius: 8px!important;
    .ant-modal-header{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
    .ant-modal-content{
        border-radius: 8px!important;
    }
    .body {
        display: flex;
        flex-direction: column;
        .emails-select {
            margin-top: 16px;
        }
    }
    .ant-btn{
        border-color: #383841;
        color: #383841;
        width: 120px;
        height: 40px!important;
        border-radius: 4px;
    }
    .ant-btn:focus, .ant-btn:hover{
        border-color: #383841;
    }
    .ant-btn.ant-btn-primary{
        background-color: #383841;
        border-color: #383841;
        color: #fff;
        width: 120px;
        height: 40px!important;
    }
    .ant-select-selector{
        border-color: #cdcdcf!important;
        border-radius: 8px!important;
    }
}
.ant-modal-mask {
    background-color: #383841;
    background-image: linear-gradient(495deg, #383841 30%, #ea4e29 90%);

    opacity: 1;
    // filter: blur(5px);
}
.modalLogoBackground{
    background-color: #ea4e29;
    position: fixed;
    left: 0;
    top: 0;
    transform: rotate(-12deg) translate(-10px, -20px);
    height: 97px;
    width: 138px;
    background-color: #ea4e29;
    border-radius: 5px;
    .logo {
        width: 88px;
        height: 35px;
        transform: rotate(12deg) translate(50px, 40px);
    }
}
.modalAnimalLogoBackground{
    position: fixed;
    right: 0;
    bottom: -20px;
    .logo {
        width: 220px;
        height: 250px;
    }
}