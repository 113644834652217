@tailwind base;
@tailwind components;
@tailwind utilities;

body { margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Nunito',
        'Josefin Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

code { font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace; }

* { font-family: 'Nunito', sans-serif;
    outline: 0; }

input,
div,
img,
span,
textarea,
button,
select,
svg,
a { -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /* vertical-align: baseline; */ }
#root { height: 100%; }

.ant-spin-dot-item { background-color: #ea4e29; }