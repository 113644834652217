.myTable{
  :global{
    .ant-table-thead{
      th{
        background-color: #383841!important;
        color: #FFFFFF!important;
      }
    }
    .ant-table,.ant-table-content, .ant-table-container{
      border-radius: 8px;
    }
  }
}