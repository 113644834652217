.container{
  width: 50%;
  height: 100%;
  margin: 0 auto;
  @media only screen and (max-width: 1000px) {
    width: 75%;
  }
  @media only screen and (max-width: 667px) {
    width: 100%;
  }
}