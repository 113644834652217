.card{
  background-color: #FFFFFF;
  min-height: 360px;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  display: flex;
  //justify-content: center;
  padding: 40px;
  flex-direction: column;
  :global{
    .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
    .react-calendar__navigation__arrow.react-calendar__navigation__next2-button{
      display: none;
    }
  }
  padding-bottom: 10px;
}
.title{
  color: #383841;
  font-size: 24px;
  font-weight: bold;
}

.calendar{
  border: none!important;
  :global{
    .react-calendar__tile--now {
      background: #f6f6f6;
    }
    .react-calendar__tile--active {
      background-color: #FFFFFF!important;
      //color: white;
    }
    .react-calendar__tile--active{
      background-color: #FFFFFF;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      abbr{
        background-color: red;
        min-width: 26px;
        max-width: 26px;
        min-height: 26px;
        max-height: 26px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        //font-size: 22px;
        //padding: 6px;
      }
    }
  }
}
.dataSection{
  background-color: #f5f5f5;
  height: 100%;
  border-radius: 8px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &_title{
    color: #252525;
    font-weight: bold;
    font-size: 16px;
  }
  &_description{
    margin-top: 16px;
    color: #8d8d8d;
    font-size: 16px;
  }
}

