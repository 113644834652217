#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    // background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
}
.main-layout {
    .ant-layout {
        z-index: 2;
    }
    .layout-header {
        display: flex;
        .header-right-side {
            flex: 1;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            padding-right: 16px;
            .choose-language{
                margin-right: 8px;
                display: flex;
                .ant-radio-button-wrapper{
                    span{
                        color: #000;
                    }
                }
                .ant-radio-button-wrapper-checked{
                    span{
                        color: #fff;
                    }
                }
                .ant-radio-button-wrapper > .ant-radio-button{
                    background-color: #ea4e29;
                    border: 1px solid #ea4e29;
                }
                .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
                    border-color: #ea4e29;
                }
                .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                    background-color: #ea4e29;
                }
            }
            .header-button {
                margin-left: 16px;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border-radius: 5px;
                border: none;
                font-size: 17px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.97;
                letter-spacing: normal;
                text-align: center;
                color: #000;
                box-shadow: none;
                .anticon {
                    display: flex;
                }
            }
            .profile-btn{
                margin-right: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                .anticon {
                    display: flex;
                }
            }
        }
        .header-left-side {
            height: 100%;
            display: flex;
            align-items: center;

            .header-button {
                margin-left: 16px;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                // width: 53px;
                height: 53px;
                border-radius: 5px;
                border: none;
                font-size: 17px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.97;
                letter-spacing: normal;
                text-align: center;
                color: #000;
                box-shadow: none;
                .anticon {
                    display: flex;
                }
            }
            .header-button-icon {
                width: 53px;
            }
        }
    }
    .page {
        position: relative;
        width: 100%;
        height: 100%;
        .breadcrubs {
            position: absolute;
        }
    }
    .side-bar-logo {
        width: 100%;
        height: 97px;
        .logo-background {
            transform: rotate(-12deg) translate(-10px, -20px);
            height: 97px;
            width: 138px;
            background-color: #ea4e29;
            border-radius: 5px;
            position: relative;
            .logo {
                width: 88px;
                height: 35px;
                transform: rotate(12deg) translate(40px, 30px);
            }
        }
    }

    .site-layout-background {
        background: #fff;
        z-index: 5;
    }
    .side-bar {
        position: sticky;
        top: 0;
        max-height: 100vh;

        width: 266px !important;
        .ant-layout-sider-children {
            display: flex;
            flex-direction: column;
        }
        background-color: #383841;
        z-index: 1;
        .ant-menu > .ant-menu-submenu:hover,
        .ant-menu > .ant-menu-item-active,
        .ant-menu > .ant-menu-submenu-active,
        .ant-menu > .ant-menu-item-open,
        .ant-menu > .ant-menu-submenu-open,
        .ant-menu > .ant-menu-item-selected,
        .ant-menu > .ant-menu-submenu-selected {
            background-color: transparent;
        }
        .ant-menu-inline,
        .ant-menu-vertical,
        .ant-menu-vertical-left {
            border-right: none;
        }
        .ant-menu-item,
        .ant-menu-submenu-title {
            transition: none;
        }
        .ant-menu-item a {
            color: inherit;
            transition: none;
        }
        .left-menu {
            background-color: inherit;
            padding-bottom: 80px;
            .title {
                // width: 121px;
                height: 19px;
                margin: 25px 85px 44px 22px;
                font-family: Nunito;
                font-size: 21px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.24;
                letter-spacing: normal;
                text-align: left;
                color: #ffffff;
            }
            .ant-menu-item-active {
                // width: 251px !important;
            }
            .ant-menu-item {
                color: #eaeaea;
                svg {
                    width: 19px;
                    height: 19px;
                }
                .menu-item-title {
                    font-family: Nunito;
                    font-size: 16px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.94;
                    letter-spacing: normal;
                    text-align: left;
                }
            }
            .ant-menu-item-selected {
                background-color: #ededee;
                height: 47px;
                display: flex;
                align-items: center;
                color: #ea4e29;
                // margin-left: 15px;
                border-bottom-left-radius: 7px;
                border-top-left-radius: 7px;
                // width: 251px;
                svg {
                    width: 40px;
                    height: 40px;
                    padding: 10px 9px 11px 10px;
                    border-radius: 7px;
                    background-color: #ea4e29;
                    color: #fff;
                }
                .menu-item-title {
                    font-family: Nunito;
                    font-size: 16px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.94;
                    letter-spacing: normal;
                    text-align: left;
                }
            }
            .ant-menu-item-selected:after {
                display: none;
            }
        }
    }
    .content {
        input,
        div,
        img,
        span,
        textarea,
        button,
        select,
        svg,
        a {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            vertical-align: baseline;
        }
    }
}

.statisticText{
    color: #fff;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 12px;
}

.statisticTextSMS{
    color: #fff;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 18px;
}

.barContainer {
    background-color: #4d4d50;
}

.barContainerSmallValue {
    background-color: #4d4d50;
    span{
        position: absolute;
        left: 5px;
    }
}

.barCompleted {
    background-color: #ea4e29;
}
.domainAnalyticsData{
    padding-left: 16px;
    //padding-right: 16px;
}

.header-btnChange{

}

.domainTitle{
    position: absolute;
    top: 10px;
    height: 10px;
    left: 16px;

}
.domainTitleContainer{
    position: relative;
}
