.card{
  background-color: #FFFFFF;
  min-height: 360px;
  height: 100%;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  display: flex;
  //justify-content: center;
  padding: 40px;
  flex-direction: column;
  //align-items: center;
}
.title{
  color: #383841;
  font-size: 24px;
  font-weight: bold;
}

.subTitle{
  color: #383841;
  font-size: 16px;
  font-weight: bold;
}

  .statisticTextSMS{
  color: #383841;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 18px;
}

.statisticText{
  color: #383841;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.barContainer {
  background-color: #c3c3c6;
}

.progressContainer{
  margin-top: 16px;
  margin-bottom: 40px;
}
