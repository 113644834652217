.domainChartReport{
  padding: 16px;
}

.label {
  fill: black;
  font-size: 16px !important;
  font-weight: bold; }
.axis{
  text{
    font-size: 12px;
    font-weight: bold;
  }
}
.tick{
}

.chartWrapper{
  max-width: 100%;
  overflow-x: scroll;

}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media print {
  .chartColl {
    //page-break-after: always;
    page-break-before: always;
  }
}