.domainChart{
  .header {
    display: flex;
    flex-direction: column;
    .text {
      font-size: 35px;
      font-family: 'Josefin Sans', sans-serif;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #ea4e29;
      margin-bottom: -14px;
    }
  }
}

.label {
  fill: black;
  font-size: 16px !important;
  font-weight: bold; }
.axis{
  text{
    font-size: 12px;
    font-weight: bold;
  }
}
.tick{
}

.chartWrapper{
  max-width: 100%;
  overflow-x: scroll;

}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.header-btn-icon{
  width: 40px;
  // display: block;
  span{
    margin: 0 !important;
  }
}

.header-btn {
  margin-right: 16px;
  background-color: #ea4e29;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 172px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ea4e29;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.97;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  .anticon {
    display: flex;
    margin-right: 12px;
  }
  &:hover, &:focus{
    background-color: #ea4e29;
  }

}
.header-btn[disabled]{
  opacity: 0.6;
}