.unsubscribe-page{
    width: 100%;
    height: 100%;
    background-color: rgba(56, 56, 65, 0.09);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    .logo{
        display: flex;
        font-size: 24px;
        align-items: flex-end;
        .title{

        }
        .subTitle{
            color: #ff3600;
        }
        div{
            margin-left: 12px;
        }
    }
}