.card{
  background-color: #FFFFFF;
  min-height: 360px;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  display: flex;
  //justify-content: center;
  padding: 40px;
  flex-direction: column;
}
.title{
  color: #383841;
  font-size: 24px;
  font-weight: bold;
}

.dataSection{
  border-radius: 8px;
  &_dataContainer{
    border-radius: 8px;
    padding: 0 16px;
    height: 44px;
    background-color: #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
      display: flex;
      align-items: center;
      svg{
        margin-right: 8px;
      }
    }
  }
  &_title{
    color: #7c7c7c;
  }
}

.subTitle{
  color: #383841;
  font-size: 16px;
  font-weight: bold;
}