.home-page{
    display: flex;
    flex-direction: column;
    .headerMain{
        display: flex;
        height: 100px;
        .text{
            font-size: 35px;
            font-family: 'Josefin Sans', sans-serif;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.44;
            letter-spacing: normal;
            text-align: left;
            color: #ea4e29;
            margin-bottom: -14px;
        }
    }
    .cards-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .card{
            flex: 1;
            margin-right: 41px;
            margin-bottom: 41px;
        }
    }
}
.analytics-table-whatsapp {
    font-family: 'Nunito', sans-serif;
    .table {
        span {
            font-size: 15px;
            font-weight: 600;
            font-family: 'Nunito', sans-serif;
            font-stretch: normal;
            font-style: normal;
            line-height: 3.13;
            letter-spacing: normal;
        }
    }
    .subject {
        width: 200px;
        cursor: pointer;
    }
    .status{
        text-transform: uppercase;
    }
    .ant-table-thead {
        .ant-table-cell {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.75;
            letter-spacing: normal;
            text-align: left;
            color: #383841;
        }
        .ant-table-selection {
            display: none !important;
        }
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
        background: #edf8fb;
    }
    .ant-table-tbody > tr > td {
        // height: 5px;
        // padding: 4px;
    }
    .ivite-number {
        font-family: 'Nunito', sans-serif;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.13;
        letter-spacing: normal;
        text-align: left;
        color: #ea4e29;
    }
    .ant-table-thead > tr > th {
        background-color: #fff;
    }
    .ant-table-cell:not(:last-child) {
        border-right: none !important;
    }
    .ant-table-cell:last-child {
        border-right: 1px solid #f0f0f0;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border: 1px solid rgba(56, 56, 65, 0.53) !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-indeterminate .ant-checkbox-inner {
        background-color: #383841;
        border: 1px solid rgba(56, 56, 65, 0.53) !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-indeterminate .ant-checkbox-inner:hover {
        background-color: #383841;
        border: 1px solid rgba(56, 56, 65, 0.53) !important;
    }
    // .ant-checkbox {
    //     .ant-checkbox-inner {
    //         border: 1px solid #1bba79;
    //         background-color: transparent;
    //     }
    // }
    .ant-table-pagination {
        position: absolute;
        right: -15px;
        top: -70px;
    }
    .report-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}
.analytics-page-logs-whatsapp {
    height: 100%;
    display: flex;
    flex-direction: column;
    .header-btn {
        margin-left: 16px;
        background-color: #ea4e29;
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 172px;
        height: 40px;
        border-radius: 5px;
        border: 1px solid #ea4e29;
        font-size: 17px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.97;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        .anticon {
            display: flex;
            margin-right: 12px;
        }
    }
    .header-btn[disabled] {
        opacity: 0.6;
    }
    .header-btn-filtra {
        width: 114px;
    }
    .header-btn-icon {
        width: 40px;
        // display: block;
        span {
            margin: 0 !important;
        }
    }
    .search-btn {
        background-color: #383841;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 32px;
        // border-radius: 5px;
        border: none;
        font-size: 17px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.97;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
    }
    .clear-btn {
        // margin-left: 16px;
        background-color: #383841;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // width: 120px;
        // height: 32px;
        // border-radius: 5px;
        border: none;
        // font-size: 17px;
        // font-weight: 600;
        // font-stretch: normal;
        // font-style: normal;
        // line-height: 2.97;
        // letter-spacing: normal;
        // text-align: center;
        // color: #ffffff;
    }
    .header {
        display: flex;
        flex-direction: column;
        .text {
            font-size: 35px;
            font-family: 'Josefin Sans', sans-serif;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.44;
            letter-spacing: normal;
            text-align: left;
            color: #ea4e29;
            margin-bottom: -14px;
        }
        .create-group-btn {
            background-color: #ea4e29;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
            width: 333px;
            height: 53px;
            border-radius: 5px;
            border: none;
            font-family: 'Nunito', sans-serif;
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.97;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            .anticon {
                display: flex;
                margin-left: 12px;
                svg {
                    width: 31px;
                    height: 31px;
                }
            }
        }
    }
    .left-side {
        flex: 1;
        max-width: 400px;
        min-width: 300px;
        display: flex;
        flex-direction: column;
        .content {
            width: 375px;
            min-height: 454px;
            border-radius: 7px;
            box-shadow: 0 0 20px 0 rgba(56, 56, 65, 0.15);
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            .header {
                height: 90px;
                border-bottom: 1px solid rgba(56, 56, 65, 0.18);
                display: flex;
                justify-content: center;
                align-items: center;
                .create-group-btn {
                    background-color: #ea4e29;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: center;
                    align-items: center;
                    width: 333px;
                    height: 53px;
                    border-radius: 5px;
                    border: none;
                    font-family: 'Nunito', sans-serif;
                    font-size: 18px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.97;
                    letter-spacing: normal;
                    text-align: center;
                    color: #ffffff;
                    .anticon {
                        display: flex;
                        margin-left: 12px;
                        svg {
                            width: 31px;
                            height: 31px;
                        }
                    }
                }
            }
            .group-list {
                .title {
                    font-family: 'Nunito', sans-serif;
                    font-size: 22px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.14;
                    letter-spacing: normal;
                    color: #0b0b0b;
                    margin: 8px 28px;
                }
                .group-table {
                    .ant-table-thead
                    > tr.ant-table-row-hover:not(.ant-table-expanded-row)
                    > td,
                    .ant-table-tbody
                    > tr.ant-table-row-hover:not(.ant-table-expanded-row)
                    > td,
                    .ant-table-thead
                    > tr:hover:not(.ant-table-expanded-row)
                    > td,
                    .ant-table-tbody
                    > tr:hover:not(.ant-table-expanded-row)
                    > td {
                        background: unset; //Change the existing color to `unset`
                        cursor: pointer;
                    }
                    margin: 8px 28px;
                    .count {
                        color: red;
                    }
                    .table-row-selected {
                        background-color: #383841;
                        color: #fff;
                        max-height: 37px;
                        td:first-child {
                            border-top-left-radius: 16px;
                        }
                        td:last-child {
                            border-top-right-radius: 16px;
                        }
                        td:first-child {
                            border-bottom-left-radius: 16px;
                        }
                        td:last-child {
                            border-bottom-right-radius: 16px;
                        }
                        .count {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    .right-side-whatsapp {
        flex: 1;
        display: flex;
        flex-direction: column;
        .header {
            height: 100px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            padding-bottom: 24px;
            .header-btn {
                margin-left: 16px;
                background-color: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 172px;
                height: 53px;
                border-radius: 5px;
                border: 1px solid rgba(56, 56, 65, 0.3);
                font-size: 17px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.97;
                letter-spacing: normal;
                text-align: center;
                color: #383841;
                .anticon {
                    display: flex;
                    margin-right: 12px;
                }
            }
            .header-btn-filtra {
                width: 114px;
            }
            .header-btn-search {
                width: 54px;
                display: flex;
                justify-content: center;
                align-items: center;
                .anticon {
                    display: flex;
                    margin-right: 0;
                }
            }
        }
        .table {
            position: relative;
            background-color: #fff;
            box-shadow: 0 0 20px 0 rgba(56, 56, 65, 0.15);
            border-radius: 7px;
            padding: 16px;
            flex: 1;
            .table-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;
                .title {
                    font-family: 'Nunito', sans-serif;
                    font-size: 25px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.12;
                    letter-spacing: normal;
                    text-align: left;
                    color: #383841;
                }
                .users {
                    display: flex;
                    font-family: 'Nunito', sans-serif;
                    font-size: 25px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.12;
                    letter-spacing: normal;
                    text-align: left;
                    color: #ea4e29;
                    .count {
                        margin-left: 8px;
                    }
                }
            }
        }
    }
    .right-side-empty {
        flex: 1;
        display: flex;
        justify-content: center;
        align-self: center;
        .ant-typography {
            color: #383841;
        }
    }
}
