.send-mail-page{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .headerMain{
    display: flex;
    height: 100px;
    .title{
      flex: 1;
      .text{
        font-size: 35px;
        font-family: 'Josefin Sans', sans-serif;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: left;
        color: #ea4e29;
        margin-bottom: -14px;

      }
    }
  }
  .body{
    .send-btn{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
      background-color: #ea4e29;
      border-color: #ea4e29;
      background: #ea4e29;
    }
    .ant-steps-item-finish .ant-steps-item-icon {
      background-color: #fff;
      border-color: #ea4e29;
      span{
        color: #ea4e29;
      }
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
      background-color: #ea4e29;
    }
    .ant-steps-item:last-child{
      flex: 2
    }
    .ant-steps-item:first-child{
      // flex: 2
    }
    .select-date{
      // width:300px;
      display: flex;
      // justify-content:space-between;
      .date-range{
        margin-left: 16px;
      }
    }
  }

  .displaymidleText{
    text-align: center;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 32px;
    font-weight: bold;
  }
  .new-mail {
    color: white;
    height: 47px;
    background-color: #383841;
    border-radius: 8px;
    min-width: 120px;

    &:hover, &:focus {
      background-color: #383841;
      color: white;
    }

    &_yellow {
      background-color: #ea4e29;
      &:hover, &:focus {
        background-color: #ea4e29;
        color: white;
      }
    }
  }
}