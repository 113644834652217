.home-page{
    margin-top: -24px;
    display: flex;
    flex-direction: column;
    .headerMain{
        display: flex;
        height: 100px;
        padding: 24px;
        .text{
            font-size: 35px;
            font-family: 'Josefin Sans', sans-serif;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.44;
            letter-spacing: normal;
            text-align: left;
            color: #ea4e29;
            margin-bottom: -14px;
        }
    }
    .cards-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .card{
            flex: 1;
            margin-right: 41px;
            margin-bottom: 41px;
        }
    }
}
.mainLogoPageContainer{

}
