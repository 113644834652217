.myTable{
      height: 47px;
      border: 1px solid #acacad;
      border-radius: 8px!important;
      :global{
            .ant-select-selector{
                  display: flex;
                  align-items: center;
                  height: 45px!important;
                  border-radius: 8px!important;
                  border: none!important;
            }
      }
}