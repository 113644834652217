.container{
  display: flex;
  flex-direction: column;
  .header{
    display: flex;
    height: 100px;
    .text{
      font-size: 35px;
      font-family: 'Josefin Sans', sans-serif;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #ea4e29;
      margin-bottom: -14px;
    }
  }
}

.saveBtn{
  background-color: #ea4e29;
  border: none;
  &:hover, &:focus{
    background-color: #ea4e29;
    opacity: 0.7;
  }
}

.notificationDeleteIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ea4e29;
  color: #fff;
  border: none;
  &:hover, &:active, &:focus{
    background-color: #ea4e29;
    color: #fff;
  }

}

.notificationEditIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: #ea4e29;
  //color: #fff;
  border: none;
  &:hover, &:active, &:focus{
    //background-color: #ea4e29;
    //color: #fff;
  }

}

.notificationFormItem{
  margin-bottom: 8px;
}