.mainLogoContainer{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ecbdb3;
  height: 250px;
  margin-left: -40px;
  margin-right: -40px;
}
.logo{
  //position: absolute;
  height: 250px;
  right: 0;
}
.textContainer{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding-right: 100px;
  @media screen and (max-width: 1300px) {
    padding-right: 50px;
  }
}

.title{
  font-weight: bold;
  color: #ea4e29;
  font-size: 32px;
  @media screen and (max-width: 1300px) {
    font-size: 24px;
  }
}

.description{
  color: #383841;
  font-size: 24px;
  @media screen and (max-width: 1300px) {
    font-size: 16px;
  }
}