.domainChart{
  padding: 16px;
  .header {
    display: flex;
    flex-direction: column;
    .text {
      font-size: 35px;
      font-family: 'Josefin Sans', sans-serif;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #ea4e29;
      margin-bottom: -14px;
    }
  }
}
.label {
  fill: black;
  font-size: 16px !important;
  font-weight: bold; }
.axis{
  text{
    font-size: 12px;
    font-weight: bold;
  }
}
@media print {
  .chartColl {
    padding-top: 20px;
    //page-break-after: always;
    page-break-before: always;
  }
}
